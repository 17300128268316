import React from 'react';
import styled from '@emotion/styled';
import { CSSProperties } from '@emotion/serialize';

const Wrapper = styled.div({
  display: 'grid',
  gridTemplateAreas: `"title date" "subTitle space" "description description"`,
  gridTemplateColumns: '1fr 120px',
  marginBottom: 8,
});

const headerStyle: CSSProperties = {
  fontWeight: 'bold',
  fontSize: 13,
  textTransform: 'uppercase',
  margin: 0,
};

const Title = styled.h3({
  ...headerStyle,
  gridArea: 'title',
});

const Date = styled.h3({
  ...headerStyle,
  textAlign: 'right',
  gridArea: 'date',
});
const SubTitle = styled.h4({
  ...headerStyle,
  textTransform: 'unset',
  gridArea: 'subTitle',
  fontWeight: 'normal',
});
const Description = styled.div({
  paddingTop: 4,
  paddingBottom: 4,
  marginBottom: 0,
  gridArea: 'description',
});

export const DatedParagraph: React.FunctionComponent<{
  from: string;
  to: string;
  title: string;
  subTitle?: React.ReactNode;
}> = (props) => (
  <Wrapper>
    <Title>{props.title}</Title>
    <Date>
      {props.from} - {props.to}
    </Date>
    {props.subTitle && <SubTitle>{props.subTitle}</SubTitle>}
    <Description>{props.children}</Description>
  </Wrapper>
);

export const Paragraph: React.FunctionComponent<{
  title: string;
  subTitle?: React.ReactNode;
}> = (props) => (
  <Wrapper>
    <Title>{props.title}</Title>
    <Description>{props.children}</Description>
  </Wrapper>
);

const BoldText = styled.div({
  fontSize: 12,
  margin: 0,
  gridArea: 'title',
});

const ConferenceName = styled.div({
  fontSize: 12,
  margin: 0,
});

export const TalkEntry: React.FunctionComponent<{
  title: string;
  conferenceName?: React.ReactNode;
}> = (props) => (
  <Wrapper>
    <BoldText>{props.children}</BoldText>
    <Date>
      <ConferenceName>{props.conferenceName}</ConferenceName>
    </Date>
  </Wrapper>
);
