// tslint:disable:no-http-string
import React from 'react';

export const EducationIcon: React.FunctionComponent<
  React.SVGProps<SVGSVGElement>
> = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 50 50"
    fill="#000"
    {...props}
  >
    <path d="M 6 2 C 3.800781 2 2 3.800781 2 6 L 2.015625 30.683594 L 2.03125 30.683594 C 2.023438 30.789063 2 30.890625 2 31 C 2 33.199219 3.800781 35 6 35 L 20.011719 35 L 20.015625 43.683594 L 20.0625 43.683594 C 20.054688 43.792969 20 43.886719 20 44 C 20 46.199219 21.800781 48 24 48 L 48 48 L 48 46 L 24 46 C 22.882813 46 22 45.117188 22 44 C 22 42.882813 22.882813 42 24 42 L 48 42 L 48 15 L 30 15 L 30 2 Z M 6 4 L 28 4 L 28 15 L 24 15 C 21.800781 15 20 16.800781 20 19 L 20.003906 27 L 6 27 C 5.277344 27 4.605469 27.210938 4.015625 27.550781 L 4 6 C 4 4.882813 4.882813 4 6 4 Z M 9 6 C 7.90625 6 7 6.90625 7 8 L 7 10 C 7 11.09375 7.90625 12 9 12 L 23 12 C 24.09375 12 25 11.09375 25 10 L 25 8 C 25 6.90625 24.09375 6 23 6 Z M 9 8 L 23 8 L 23 10 L 9 10 Z M 24 17 L 46 17 L 46 40 L 24 40 C 23.265625 40 22.609375 40.257813 22.015625 40.609375 L 22 19 C 22 17.882813 22.882813 17 24 17 Z M 27 19 C 25.90625 19 25 19.90625 25 21 L 25 23 C 25 24.09375 25.90625 25 27 25 L 41 25 C 42.09375 25 43 24.09375 43 23 L 43 21 C 43 19.90625 42.09375 19 41 19 Z M 27 21 L 41 21 L 41 23 L 27 23 Z M 6 29 L 20.007813 29 L 20.011719 33 L 6 33 C 4.882813 33 4 32.117188 4 31 C 4 29.882813 4.882813 29 6 29 Z " />
  </svg>
);
