import IndexLayout from '../layouts';
import * as React from 'react';
import { FluidObject } from 'gatsby-image';
import { Helmet } from 'react-helmet';
import styled from '@emotion/styled';
import { graphql } from 'gatsby';
import { BriefcaseIcon } from '../components/icons/briefcase';
import { ProjectManagerIcon } from '../components/icons/projectManager';
import { EducationIcon } from '../components/icons/education';
import { Global } from '@emotion/core';
import { css } from 'emotion';
import { ProgressBar } from '../components/ProgressBar';
import {
  DatedParagraph,
  Paragraph,
  TalkEntry,
} from '../components/DatedParagraph';

const dark = '#232f3c';
const lightAccent = '#e8e8e8';
const light = '#fff';

const pageWidth = 800;
const pageHeight = Math.round(pageWidth * Math.sqrt(2));

const mobileMQ = `@media only screen and (max-width: ${pageWidth}px)`;

const iconCss = css({
  width: 32,
});

const noPrintCss = css({
  '@media print': {
    display: 'none',
  },
});

const printOnlyCss = css({
  display: 'none',
  '@media print': {
    display: 'block',
  },
});

const Wrapper = styled.div({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  minHeight: '100vh',
  padding: 24,
  background: light,
  '@media print': {
    padding: 0,
    a: {
      color: '#000',
    },
  },
  [mobileMQ]: {
    padding: 0,
  },
});

const PageLayout = styled.div({
  width: pageWidth,
  // height: pageHeight,
  background: light,
  display: 'grid',
  gridTemplateAreas: `"header header" "summary details"`,
  gridTemplateRows: '132px auto',
  gridTemplateColumns: `1fr 2fr`,
  boxShadow: '0 0 24px rgba(0,0,0,0.08)',
  fontFamily: 'Lato',
  '@media print': {
    width: '100vw',
    // height: '100vh',
    boxShadow: 'none',
  },
  [mobileMQ]: {
    gridTemplateAreas: `"header" "details" "summary"`,
    gridTemplateRows: '96px auto',
    gridTemplateColumns: `1fr`,
    maxWidth: '100%',
    width: '100%',
    maxHeight: 'unset',
    height: '100%',
  },
});

const Header = styled.header({
  background: dark,
  color: light,
  display: 'grid',
  position: 'relative',
  overflow: 'visible',
  gridTemplateColumns: '1fr 2fr',
  gridTemplateAreas: `"spacer content"`,
  gridArea: 'header',
  [mobileMQ]: {
    gridTemplateAreas: `"spacer content"`,
  },
});

const HeaderContent = styled.div({
  gridArea: 'content',
  display: 'grid',
  gridTemplateColumns: '64px auto',
  gridTemplateAreas: `"icon content"`,
  [mobileMQ]: {
    gridTemplateColumns: '24px auto',
  },
});

const TitleWrapper = styled.div({
  gridArea: 'content',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
});

const Name = styled.h1({
  color: light,
  fontSize: 64,
  margin: 0,
  textTransform: 'uppercase',
  [mobileMQ]: {
    fontSize: 36,
  },
});

const Profession = styled.h2({
  color: light,
  fontSize: 24,
  margin: 0,
  fontWeight: 'normal',
  textTransform: 'uppercase',
  [mobileMQ]: {
    fontSize: 18,
  },
});

const Summary = styled.aside({
  display: 'flex',
  gridArea: 'summary',
  background: lightAccent,
  flexDirection: 'column',
  alignItems: 'center',
  paddingTop: 112,
  [mobileMQ]: {
    paddingTop: 0,
  },
});

const Details = styled.main({
  display: 'grid',
  gridArea: 'details',
  paddingRight: 24,
  paddingBottom: 16,
  paddingTop: 8,
  [mobileMQ]: {
    paddingTop: 36,
    paddingBottom: 16,
    gridGap: 8,
  },
});

const avatarSize = 192;
const avatarLeftSpace = (pageWidth / 3 - avatarSize) / 2;

const avatarSizeSmall = 128;

const Avatar = styled.img({
  objectFit: 'cover',
  objectPosition: 'center',
  borderRadius: '50%',
  position: 'absolute',
  bottom: avatarSize / -2,
  left: avatarLeftSpace,
  width: avatarSize,
  height: avatarSize,
  [mobileMQ]: {
    bottom: -32,
    width: avatarSizeSmall,
    height: avatarSizeSmall,
    left: 8,
  },
});

const DetailSection = styled.section({
  display: 'grid',
  width: '100%',
  gridTemplateColumns: '64px auto',
  gridTemplateRows: '30px auto',
  gridTemplateAreas: `"icon title" "space content"`,
});

const DetailTitle = styled.h2({
  margin: 0,
  gridArea: 'title',
  textTransform: 'uppercase',
  display: 'flex',
  alignItems: 'center',
  fontSize: '16px',
  lineHeight: '30px',
});
const DetailContent = styled.div({
  margin: 0,
  gridArea: 'content',
  fontSize: '12px',
  lineHeight: 1.5,
});
const IconWrapper = styled.div({
  gridArea: 'icon',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
});

const Detail: React.FC<{
  title: string;
  icon: React.ReactNode;
}> = (props) => (
  <DetailSection>
    <IconWrapper>{props.icon}</IconWrapper>
    <DetailTitle>{props.title}</DetailTitle>
    <DetailContent>{props.children}</DetailContent>
  </DetailSection>
);

const SummarySection = styled.section({
  display: 'grid',
  width: '100%',
  justifyContent: 'center',
  gridTemplateRows: '40px auto',
  gridTemplateColumns: 'calc(100% - 32px)',
  gridTemplateAreas: `"title" "content"`,
  textAlign: 'center',
  paddingBottom: 16,
  alignItems: 'center',
});
const SummaryTitle = styled.h3({
  margin: 0,
  gridArea: 'title',
  fontWeight: 'bold',
  fontSize: 18,
  textTransform: 'uppercase',
});
const SummaryContent = styled.div({
  margin: 0,
  gridArea: 'content',
});
const SummarySubHeader = styled.h4({
  fontWeight: 'bold',
  fontSize: 14,
  margin: 0,
  '&:not(:first-of-type)': {
    marginTop: 8,
  },
});
const SummarySubContent = styled.div({
  fontSize: 14,
  lineHeight: '22px',
});

const SummaryPart: React.FC<{ title: string }> = ({ title, children }) => (
  <SummarySection>
    <SummaryTitle>{title}</SummaryTitle>
    <SummaryContent>{children}</SummaryContent>
  </SummarySection>
);

const SkillGrid = styled.ul({
  display: 'grid',
  gridAutoRows: '40px',
  gridRowGap: 8,
  gridColumnGap: 16,
  gridTemplateColumns: '1fr 1fr',
  padding: 0,
  margin: 0,
});

const SkillList = styled.ul({
  display: 'flex',
  flexWrap: 'wrap',
  gridAutoColumns: 'min-content',
  padding: 0,
  margin: 0,
});

const SkillWrapper = styled.li({
  textAlign: 'left',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  padding: 0,
  margin: 0,
});
const SkillPill = styled.li({
  textAlign: 'center',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '6px 8px',
  background: dark,
  color: light,
  borderRadius: 9,
  margin: '0 2px 4px',
  height: 20,
  lineHeight: '20px',
});
const SkillName = styled.span({ fontWeight: 'bold', fontSize: 14 });

const DiscreetParagraph = styled.p({
  marginBottom: 2,
});

const ExternalLink: React.FC<{ href: string }> = (props) => (
  <a href={props.href} target="_blank">
    {props.children}
  </a>
);

const ProjectItem = styled.li({
  paddingLeft: 0,
  lineHeight: 1.2,
});
export interface CVProps {
  data: {
    authorYaml: {
      id: string;
      name: string;
      surName: string;
      jobTitle: string;
      profile_image: {
        childImageSharp: {
          fluid: FluidObject;
        };
      };
    };
  };
}

const skills = [
  'Agile development',
  'Presenting',
  'Mentoring',
  'Project lead',
  'English (fluent)',
  'German (native)',
];

const techs = [
  { name: 'TypeScript', value: 100 },
  { name: 'React', value: 100 },
  { name: 'Node.Js', value: 95 },
  { name: 'CSS/SASS/LESS', value: 90 },
  { name: 'React Native', value: 80 },
  { name: 'Java/Kotlin', value: 75 },
  { name: 'ObjC/Swift', value: 75 },
  { name: 'Go', value: 65 },
];

const CV: React.FC<CVProps> = (props) => {
  return (
    <IndexLayout>
      <Global
        styles={{
          '@media print': {
            body: {
              margin: 0,
            },
          },
        }}
      />
      <Helmet>
        <title>
          {props.data.authorYaml.name} {props.data.authorYaml.surName}
        </title>
      </Helmet>
      <Wrapper>
        <PageLayout>
          <Header>
            <HeaderContent>
              <TitleWrapper>
                <Name>
                  {props.data.authorYaml.name} {props.data.authorYaml.surName}
                </Name>
                <Profession>{props.data.authorYaml.jobTitle}</Profession>
              </TitleWrapper>
            </HeaderContent>
            <Avatar
              src={
                props.data.authorYaml.profile_image.childImageSharp.fluid.src
              }
            />
          </Header>
          <Details>
            <Detail
              icon={<ProjectManagerIcon className={iconCss} />}
              title="About me"
            >
              <DiscreetParagraph>
                I am a software developer specialized on frontend development
                with 10 years of work experience. Over the last 6 years I became
                a well-known expert on Typescript and React in the companies I
                worked. But my problem-solving skills are not limited to work in
                the browser I also don’t shy away from optimizing database
                queries, performance optimizations in deeply nested business
                logic or debugging third party native SDKs on iOS and Android.
              </DiscreetParagraph>
            </Detail>
            <Detail
              icon={<BriefcaseIcon className={`${iconCss}`} />}
              title="Work experience"
            >
              <DatedParagraph
                title="Senior Frontend Developer"
                subTitle={
                  <ExternalLink href="https://aboutyou.de">
                    ABOUT YOU
                  </ExternalLink>
                }
                from="2019"
                to="present"
              >
                I am working on the desktop web application for the ABOUT YOU
                online shop. Besides the work on server and client side
                rendering I took over a leading role for the development of our
                tracking solution.
                <ul>
                  <ProjectItem>
                    6.0 Redesign - On the start I worked on the complete
                    re-design of the About You shop application
                  </ProjectItem>
                  <ProjectItem>
                    TrackingSDK - I took responsibility to lead the development
                    of a unified approach for our tracking implementation
                  </ProjectItem>
                  <ProjectItem>
                    Help Center - In the first really responsive UI project for
                    About You I took over the technical lead
                  </ProjectItem>
                </ul>
              </DatedParagraph>
              <DatedParagraph
                title="Software Developer"
                subTitle={
                  <ExternalLink href="https://cap3.de">Cap3</ExternalLink>
                }
                from="2014"
                to="2019"
              >
                For Cap3 I worked on a vast amount of different projects. My
                responsibilities ranged from feature implementation over
                consulting to project management
                <ul>
                  <ProjectItem>
                    <ExternalLink href="https://www.cap3.de/en/projects/panel-app">
                      Panel APP
                    </ExternalLink>{' '}
                    - multilingual react-native app with react based back-office
                    and a Scala backend
                  </ProjectItem>
                  <ProjectItem>
                    <ExternalLink href="https://www.cap3.de/projekte/e-learning-app">
                      Quizcards
                    </ExternalLink>{' '}
                    - e-learning app developed as white label solution with
                    polymer.js and SASS
                  </ProjectItem>
                  <ProjectItem>
                    <ExternalLink href="https://www.cap3.de/projekte/sicherheitssystem-fuer-alleinarbeit">
                      Selene
                    </ExternalLink>{' '}
                    - security application running on android phones for alone
                    workers, with features like fall detection and live
                    positional tracking through a backoffice
                  </ProjectItem>
                  <ProjectItem className={`${noPrintCss}`}>
                    <ExternalLink href="https://www.cap3.de/projekte">
                      more...
                    </ExternalLink>
                  </ProjectItem>
                </ul>
              </DatedParagraph>
              <DatedParagraph
                title="Lecturer"
                subTitle={
                  <ExternalLink href="https://www.wak-sh.de/">
                    Wirtschaftsakademie Schleswig-Holstein
                  </ExternalLink>
                }
                from="2019"
                to="2019"
              >
                In the winter semester 2019 I have been teaching students of
                business informatics mobile app development with React Native,
                Flutter and native android development.
              </DatedParagraph>
              <DatedParagraph
                title="Instructor"
                subTitle={
                  <ExternalLink href="https://opencampus.sh/">
                    opencampus
                  </ExternalLink>
                }
                from="2017"
                to="2019"
              >
                As an instructor for opencampus I developed and teached courses
                on topics like developing a web site or how to use IoT to
                automate your home.
              </DatedParagraph>
              <DatedParagraph
                title="Research Assistant / Tutor"
                subTitle={
                  <ExternalLink href="https://www.uni-kiel.de/en/">
                    University of Kiel
                  </ExternalLink>
                }
                from="2010"
                to="2014"
              >
                During my time at the university of Kiel I worked as a tutor to
                teach practical software development with Java.
              </DatedParagraph>
              <DatedParagraph
                title="Student Partner"
                subTitle={
                  <ExternalLink href="https://msdn.microsoft.com/en-us/microsoftstudentpartners.aspx">
                    Microsoft
                  </ExternalLink>
                }
                from="2012"
                to="2014"
              >
                As a Microsoft Student Partner I organized events like
                hackathons and gave talks about Microsoft innovations.
              </DatedParagraph>
              <Paragraph title="Conference Talks">
                <TalkEntry date="2019" conferenceName={'AgentConf 2019'}>
                  <ExternalLink href="https://agent.sh/agent-conf-2019">
                    React is just TypeScript
                  </ExternalLink>
                </TalkEntry>
              </Paragraph>
            </Detail>
            <Detail
              icon={<EducationIcon className={`${iconCss}`} />}
              title="Education"
            >
              <DatedParagraph title="University of Kiel" from="2009" to="2014">
                BSc: Computer science
              </DatedParagraph>
              <DatedParagraph
                title="Technical University Berlin"
                from="2007"
                to="2009"
              >
                Technical computer science
              </DatedParagraph>
            </Detail>
          </Details>
          <Summary>
            <SummaryPart title="Contact">
              <SummarySubHeader>Email</SummarySubHeader>
              <SummarySubContent>
                <a href="mailto:kalle-ott@live.de">kalle-ott@live.de</a>
              </SummarySubContent>
              <SummarySubHeader>Website</SummarySubHeader>
              <SummarySubContent>
                <a href="https://kalleott.de">www.kalleott.de</a>
              </SummarySubContent>
              <SummarySubHeader>Home</SummarySubHeader>
              <SummarySubContent>
                Ferdinand-Beit-Str. 9a
                <br />
                20099, Hamburg
                <br />
                Germany
              </SummarySubContent>
            </SummaryPart>
            <SummaryPart title="Skills">
              <SkillList>
                {skills.map((skill, index) => (
                  <SkillPill key={index}>
                    <SkillName>{skill}</SkillName>
                  </SkillPill>
                ))}
              </SkillList>
            </SummaryPart>
            <SummaryPart title="Technologies">
              <SkillGrid>
                {techs.map((tech, index) => (
                  <SkillWrapper key={index}>
                    <SkillName>{tech.name}</SkillName>
                    <ProgressBar progress={tech.value} />
                  </SkillWrapper>
                ))}
              </SkillGrid>
            </SummaryPart>
            <SummaryPart title="Follow me">
              <SummarySubHeader>Twitter</SummarySubHeader>
              <SummarySubContent>
                <a href="https://twitter.com/kaoDev">www.twitter.com/kaoDev</a>
              </SummarySubContent>
              <SummarySubHeader>GitHub</SummarySubHeader>
              <SummarySubContent>
                <a href="https://www.github.com/kaoDev/">
                  www.github.com/kaoDev
                </a>
              </SummarySubContent>
              <SummarySubHeader>LinkedIn</SummarySubHeader>
              <SummarySubContent>
                <a href="https://www.linkedin.com/in/kalle-ott/">
                  www.linkedin.com/in/kalle-ott
                </a>
              </SummarySubContent>
              <SummarySubHeader className={`${printOnlyCss}`}>
                This document online
              </SummarySubHeader>
              <SummarySubContent className={`${printOnlyCss}`}>
                <a href="https://cv.kalleott.de/">cv.kalleott.de</a>
              </SummarySubContent>
            </SummaryPart>
          </Summary>
        </PageLayout>
      </Wrapper>
    </IndexLayout>
  );
};

export default CV;

export const pageQuery = graphql`
  query CVQuery {
    authorYaml {
      id
      name
      surName
      jobTitle
      profile_image {
        childImageSharp {
          fluid(maxWidth: 3720) {
            src
          }
        }
      }
    }
  }
`;
