import React, { HTMLProps } from 'react';
import styled from '@emotion/styled';

const Progress = styled.div({
  background: '#232f3c',
  height: '100%',
});

const Wrapper = styled.div({
  background: '#aaa',
  height: 10,
  width: '100%',
});

interface Props extends HTMLProps<HTMLDivElement> {
  progress: number;
}
export const ProgressBar: React.FunctionComponent<Props> = ({
  progress: completed,
  ...rest
}) => {
  const style = {
    width: `${completed}%`,
  };

  return (
    <Wrapper {...rest}>
      <Progress className="progressbar-progress" style={style} />
    </Wrapper>
  );
};
